/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { site, sanitySiteSettings } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        sanitySiteSettings {
          metaTagsKeyword
          metaTagsAuthor
          metaTagsDescription
          metaTagsTitle
          ogImage {
            asset {
              url
            }
          }
          ogUrl
          fbAppId
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} - %s`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: (sanitySiteSettings.metaTagsKeyword || []).join(','),
        },
        {
          property: 'og:title',
          content: sanitySiteSettings.metaTagsTitle,
        },
        {
          property: 'og:image',
          content: sanitySiteSettings.ogImage.asset.url,
        },
        {
          property: 'og:url',
          content: sanitySiteSettings.ogUrl,
        },
        {
          property: 'og:description',
          content: sanitySiteSettings.metaTagsDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'fb:app_id',
          content: sanitySiteSettings.fbAppId,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: sanitySiteSettings.metaTagsAuthor,
        },
        {
          name: 'twitter:title',
          content: sanitySiteSettings.metaTagsTitle,
        },
        {
          name: 'twitter:description',
          content: sanitySiteSettings.metaTagsDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
