const colors = {
  text: 'black',
  background: 'white',
  backgroundPale: '#DFE4E933',
  primary: '#05C890',
  accent: '#2319AC',
  muted: '#C9CED6',
  teal100: '#C7F4E7',
  teal200: '#84E4C8',
  teal300: '#05C890',
  teal400: '#0BB383',
  teal500: '#008F66',
  black: '#15161A',
  footerBlack: '#26272E',
  white: '#FFFFFF',
  pale700: '#414B56',
  pale600: '#5A6172',
  pale600_40: '#5A617266',
  pale500: '#8A94A6',
  pale400: '#B5BBC6',
  pale300: '#C9CED6',
  pale200: '#DFE4E9',
  pale200_40: '#DFE4E966', // 40% opacity
  pale200_30_100_opacity: '#F5F7F8',
  pale100: '#FBFBFB',
  violet200: '#8659D0',
  violet300: '#4840BB',
  violet400: '#2319AC',
  red200: '#FE9791',
  red300: '#F55248',
  blue200: '#E8F3FF',
  blue300: '#8BC3FF',
  blue400: '#007AFF',
  yellow: '#FAC753',
  pureBlack: '#000000',
  yellow50: '#F9FAF1',
  indigo100: '#9F8AEA',
  indigo200: '#7A56FC',
  indigo300: '#4B25D8',
  indigo400: '#1F0680',
  indigo500: '#0C003F',
  neon: '#B4FC00',
};

export default colors;
