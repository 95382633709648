import buttonTheme from './themes/button';
import textTheme from './themes/text';
import colors from './themes/colors';

const breakpoints = ['667px', '960px', '1280px'];

export default {
  breakpoints,
  colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
    monospace: 'Poppins, sans-serif',
    heading2: 'nimbus-sans-extended, sans-serif',
  },
  fontWeights: {
    light: 300,
    body: 400,
    heading: 500,
    bold: 500,
    extrabold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {
    primary: {
      bg: 'teal300',
      color: 'white',
    },
    light: {
      bg: 'white',
      color: 'black',
    },
    hero: {
      bg: 'white',
      color: 'teal300',
    },
  },
  text: textTheme,
  buttons: buttonTheme,
  forms: {
    input: {
      fontFamily: 'body',
      fontWeight: 'body',
    },
    label: {
      fontFamily: 'body',
    },
    select: {
      fontFamily: 'body',
    },
  },
  styles: {
    root: {
      fontFamily: 'heading',
      fontWeight: 'body',
    },
    body: {
      margin: 1,
      color: 'tomato',
    },
  },
};
