import React from 'react';
import { Box } from 'rebass';

export const wrapperWidth = [318, 768, 940];

const Wrapper = props => (
  <Box width="100%" {...props}>
    <Box
      width={props.isLegacy === false ? '100%' : wrapperWidth}
      mx={props.isLegacy === false ? 0 : 'auto'}
    >
      {props.children}
    </Box>
  </Box>
);

export default Wrapper;
