export default {
  primary: {
    color: 'white',
    cursor: 'pointer',
    bg: 'primary',
    fontFamily: 'body',
    fontWeight: 500,
    outline: 'none',
    whitespace: 'nowrap',
    '&:hover': {
      bg: 'teal200',
    },
    '&:active': {
      bg: 'teal400',
    },
  },
  secondary: {
    color: 'teal300',
    cursor: 'pointer',
    bg: 'white',
    fontFamily: 'body',
    fontWeight: 500,
    outline: 'none',
    whitespace: 'nowrap',
    '&:active': {
      color: 'teal200',
    },
  },
  purple: {
    color: 'white',
    cursor: 'pointer',
    bg: 'violet300',
    fontFamily: 'body',
    fontWeight: 500,
    borderRadius: '72px',
    outline: 'none',
    whitespace: 'nowrap',
    '&:hover': {
      bg: 'violet200',
    },
    '&:active': {
      bg: 'violet400',
    },
  },
  accent: {
    color: 'white',
    cursor: 'pointer',
    bg: 'accent',
    fontFamily: 'body',
    fontWeight: 500,
    outline: 'none',
    whitespace: 'nowrap',
    '&:hover': {
      bg: 'violet200',
    },
    '&:active': {
      bg: 'violet300',
    },
  },
  navCTA: {
    color: 'white',
    cursor: 'pointer',
    bg: 'violet300',
    outline: 'none',
    borderRadius: 72,
    fontFamily: 'body',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
    whitespace: 'nowrap',
    '&:hover': {
      bg: 'violet200',
    },
    '&:active': {
      bg: 'violet400',
    },
  },
  navCTAPrimary: {
    color: 'white',
    cursor: 'pointer',
    bg: 'primary',
    outline: 'none',
    borderRadius: 72,
    fontFamily: 'body',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
    whitespace: 'nowrap',
    '&:hover': {
      bg: 'teal200',
    },
    '&:active': {
      bg: 'teal400',
    },
  },
  outline: {
    color: 'pale700',
    cursor: 'pointer',
    bg: 'white',
    border: '1.5px solid #5A6172',
    borderRadius: '72px',
    fontFamily: 'body',
    fontWeight: 500,
    outline: 'none',
    whitespace: 'nowrap',
    '&:hover': {
      border: '1.5px solid #8A94A6',
    },
    '&:active': {
      color: 'black',
      border: '1.5px solid black',
    },
  },
  link: {
    color: 'teal300',
    cursor: 'pointer',
    bg: 'white',
    borderRadius: '72px',
    fontFamily: 'body',
    fontWeight: 500,
    outline: 'none',
    border: 'none',
    '&:hover': {
      color: 'teal200',
    },
    '&:active': {
      color: 'teal400',
    },
  },
  primaryClear: {
    color: 'teal300',
    cursor: 'pointer',
    bg: '#00000000',
    borderRadius: '72px',
    fontFamily: 'body',
    fontWeight: 500,
    outline: 'none',
    border: 'none',
    '&:hover': {
      color: 'teal200',
    },
    '&:active': {
      color: 'teal400',
    },
  },
  neon: {
    color: '#1E1E1E',
    cursor: 'pointer',
    bg: 'neon',
    borderRadius: ['12.5px', '12.5px', '17.5px'],
    py: ['4px', '4px', '10px'],
    fontFamily: 'heading2',
    fontWeight: 'body',
    fontSize: ['10px', '10px', '18px'],
    outline: 'none',
    border: 'none',
    '&:hover': {
      color: 'neon',
      bg: '#1E1E1E',
    },
  },
};
