const fontSizes = [11, 13, 14, 16, 18, 20, 32, 50, 60, 80];

export default {
  h910: {
    fontFamily: 'heading',
    fontWeight: 600,
    lineHeight: '72px',
    fontSize: ['70px'],
    letterSpacing: '0.2px',
  },
  h900: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: ['46px', '46px', '72px'],
    fontSize: [fontSizes[6], fontSizes[6], [8]],
    letterSpacing: ['-0.03em', '-0.03em', '0.2px'],
  },
  h900Hero: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: ['54px', '54px', '72px'],
    fontSize: ['40px', '40px', fontSizes[8]],
    letterSpacing: ['-0.03em', '-0.03em', '0.2px'],
  },
  h820: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    lineHeight: 'heading',
    fontSize: 42,
    letterSpacing: '-0.3px',
  },
  h810: {
    fontFamily: 'heading',
    fontWeight: 'medium',
    lineHeight: 'heading',
    fontSize: fontSizes[7],
    letterSpacing: '-0.3px',
  },
  h800: {
    fontFamily: 'heading',
    fontWeight: 'light',
    lineHeight: 'heading',
    fontSize: fontSizes[7],
    letterSpacing: '-0.3px',
  },
  h700Medium: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: ['28px', '28px', '44px'],
    fontSize: ['28px', '28px', fontSizes[6]],
    letterSpacing: '0.2px',
  },
  h700Regular: {
    fontFamily: 'heading',
    fontWeight: 'normal',
    lineHeight: '44px',
    fontSize: fontSizes[6],
    letterSpacing: '0.2px',
  },
  h600Bold: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    lineHeight: '32px',
    fontSize: fontSizes[5],
    letterSpacing: '0.2px',
  },
  h600: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: '32px',
    fontSize: fontSizes[5],
    letterSpacing: '0.2px',
  },
  h500Medium: {
    fontFamily: 'heading',
    fontWeight: 'body',
    lineHeight: '26px',
    fontSize: fontSizes[4],
    letterSpacing: '0.2px',
  },
  h500Regular: {
    fontFamily: 'heading',
    fontWeight: 'normal',
    lineHeight: '26px',
    fontSize: fontSizes[4],
    letterSpacing: '0.2px',
  },
  h400Bold: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    lineHeight: '28px',
    fontSize: fontSizes[3],
    letterSpacing: '0.2px',
  },
  h400Medium: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: '28px',
    fontSize: fontSizes[3],
    letterSpacing: '0.2px',
  },
  h400Regular: {
    fontFamily: 'heading',
    fontWeight: 'normal',
    lineHeight: '28px',
    fontSize: fontSizes[3],
    letterSpacing: '0.2px',
  },
  h300Medium: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: '20px',
    fontSize: fontSizes[2],
    letterSpacing: '0.2px',
  },
  h300Regular: {
    fontFamily: 'heading',
    fontWeight: 'normal',
    lineHeight: '22px',
    fontSize: fontSizes[2],
    letterSpacing: '0.2px',
  },
  h200Medium: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: '13px',
    fontSize: fontSizes[1],
    letterSpacing: '0.2px',
  },
  h200Regular: {
    fontFamily: 'heading',
    fontWeight: 'normal',
    lineHeight: '18px',
    fontSize: fontSizes[1],
    letterSpacing: '0.2px',
  },
  h100: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: '16px',
    fontSize: fontSizes[0],
    letterSpacing: '0.2px',
  },
  introDescription: {
    fontFamily: 'heading',
    fontWeight: 'normal',
    lineHeight: '32px',
    fontSize: fontSizes[5],
    letterSpacing: '0.2px',
  },
  h920: {
    fontFamily: 'heading2',
    fontWeight: 'extrabold',
    lineHeight: '120%',
    fontSize: ['34px', '34px', '58px'],
  },
  subH920: {
    fontFamily: 'heading',
    fontWeight: 'body',
    lineHeight: '140%',
    fontSize: [fontSizes[1], fontSizes[1], '19px'],
  },
  h300MediumUpdated: {
    fontFamily: 'heading2',
    fontWeight: 'heading',
    lineHeight: '20px',
    fontSize: ['11px', '11px', fontSizes[2]],
    letterSpacing: '0.2px',
  },
  h700MediumUpdated: {
    fontFamily: 'heading2',
    fontWeight: 'heading',
    lineHeight: ['28px', '28px', '44px'],
    fontSize: ['28px', '28px', fontSizes[6]],
    letterSpacing: '0.2px',
  },
};
